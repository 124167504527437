import React from 'react';

const EBIFooter: React.FC = () => {
  return (
    <footer className="vf-footer" data-vf-google-analytics-region="embl-footer">
      <div className="vf-footer__inner">
        <p className="vf-footer__notice">
          <a
            className="vf-footer__link"
            href="//www.ebi.ac.uk/about/our-impact"
          >
            EMBL-EBI is the home for big data in biology. We help scientists
            exploit complex information to make discoveries that benefit
            humankind.
          </a>
        </p>
        <div className="vf-footer__links-group | vf-grid">
          <div className="vf-links">
            <h4 className="vf-links__heading">
              <a className="vf-heading__link" href="//www.ebi.ac.uk/services">
                Services
              </a>
            </h4>
            <ul className="vf-links__list | vf-list">
              <li className="vf-list__item">
                <a href="//www.ebi.ac.uk/services" className="vf-list__link">
                  By topic
                </a>
              </li>
              <li className="vf-list__item">
                <a
                  href="//www.ebi.ac.uk/services/all"
                  className="vf-list__link"
                >
                  By name (A-Z)
                </a>
              </li>
              <li className="vf-list__item">
                <a href="//www.ebi.ac.uk/support" className="vf-list__link">
                  Help &amp; Support
                </a>
              </li>
              <li className="vf-list__item">
                <a href="//www.ebi.ac.uk/licencing" className="vf-list__link">
                  Licensing
                </a>
              </li>
              <li className="vf-list__item">
                <a
                  href="//www.ebi.ac.uk/long-term-data-preservation"
                  className="vf-list__link"
                >
                  Long-term data preservation
                </a>
              </li>
            </ul>
          </div>

          <div className="vf-links">
            <h4 className="vf-links__heading">
              <a className="vf-heading__link" href="//www.ebi.ac.uk/research">
                Research
              </a>
            </h4>
            <ul className="vf-links__list | vf-list">
              <li className="vf-list__item">
                <a
                  href="//www.ebi.ac.uk/research/publications"
                  className="vf-list__link"
                >
                  Publications
                </a>
              </li>
              <li className="vf-list__item">
                <a
                  href="//www.ebi.ac.uk/research/groups"
                  className="vf-list__link"
                >
                  Research groups
                </a>
              </li>
              <li className="vf-list__item vf-footer__notice">
                <a
                  href="//www.ebi.ac.uk/research/postdocs"
                  className="vf-list__link"
                >
                  Postdocs
                </a>
                &amp;
                <a
                  href="//www.ebi.ac.uk/research/eipp"
                  className="vf-list__link"
                >
                  PhDs
                </a>
              </li>
            </ul>
          </div>

          <div className="vf-links">
            <h4 className="vf-links__heading">
              <a className="vf-heading__link" href="//www.ebi.ac.uk/training">
                Training
              </a>
            </h4>
            <ul className="vf-links__list | vf-list">
              <li className="vf-list__item">
                <a
                  href="//www.ebi.ac.uk/training/live-events"
                  className="vf-list__link"
                >
                  Live training
                </a>
              </li>
              <li className="vf-list__item">
                <a
                  href="//www.ebi.ac.uk/training/on-demand"
                  className="vf-list__link"
                >
                  On-demand training
                </a>
              </li>
              <li className="vf-list__item">
                <a
                  href="//www.ebi.ac.uk/training/trainer-support"
                  className="vf-list__link"
                >
                  Support for trainers
                </a>
              </li>
              <li className="vf-list__item">
                <a
                  href="//www.ebi.ac.uk/training/contact-us"
                  className="vf-list__link"
                >
                  Contact organisers
                </a>
              </li>
            </ul>
          </div>

          <div className="vf-links">
            <h4 className="vf-links__heading">
              <a className="vf-heading__link" href="//www.ebi.ac.uk/industry">
                Industry
              </a>
            </h4>
            <ul className="vf-links__list | vf-list">
              <li className="vf-list__item">
                <a
                  href="//www.ebi.ac.uk/industry/private"
                  className="vf-list__link"
                >
                  Members Area
                </a>
              </li>
              <li className="vf-list__item">
                <a
                  href="//www.ebi.ac.uk/industry/workshops"
                  className="vf-list__link"
                >
                  Workshops
                </a>
              </li>
              <li className="vf-list__item">
                <a
                  href="//www.ebi.ac.uk/industry/sme-forum"
                  className="vf-list__link"
                >
                  SME Forum
                </a>
              </li>
              <li className="vf-list__item">
                <a
                  href="//www.ebi.ac.uk/industry/contact"
                  className="vf-list__link"
                >
                  Contact Industry programme
                </a>
              </li>
            </ul>
          </div>

          <div className="vf-links">
            <h4 className="vf-links__heading">
              <a className="vf-heading__link" href="//www.ebi.ac.uk/about">
                About
              </a>
            </h4>
            <ul className="vf-links__list | vf-list">
              <li className="vf-list__item">
                <a
                  href="//www.ebi.ac.uk/about/contact"
                  className="vf-list__link"
                >
                  Contact us
                </a>
              </li>
              <li className="vf-list__item">
                <a
                  href="//www.ebi.ac.uk/about/events"
                  className="vf-list__link"
                >
                  Events
                </a>
              </li>
              <li className="vf-list__item">
                <a href="//www.ebi.ac.uk/about/jobs" className="vf-list__link">
                  Jobs
                </a>
              </li>
              <li className="vf-list__item">
                <a href="//www.ebi.ac.uk/about/news" className="vf-list__link">
                  News
                </a>
              </li>
              <li className="vf-list__item">
                <a
                  href="//www.ebi.ac.uk/about/people"
                  className="vf-list__link"
                >
                  People &amp; groups
                </a>
              </li>
              <li className="vf-list__item">
                <a href="//intranet.ebi.ac.uk" className="vf-list__link">
                  Intranet for staff
                </a>
              </li>
            </ul>
          </div>
        </div>

        <p className="vf-footer__legal">
          <span className="vf-footer__legal-text">
            <a
              className="vf-footer__link"
              href="https://www.google.co.uk/maps/place/Hinxton,+Saffron+Walden+CB10+1SD/@52.0815334,0.1891518,17z/data=!3m1!4b1!4m5!3m4!1s0x47d87ccbfbd2538b:0x7bbdb4cde2779ff3!8m2!3d52.0800838!4d0.186415"
            >
              EMBL-EBI, Wellcome Genome Campus, Hinxton, Cambridgeshire, CB10
              1SD, UK.
            </a>
          </span>
          <span className="vf-footer__legal-text">
            <a className="vf-footer__link" href="tel:00441223494444">
              Tel: +44 (0)1223 49 44 44
            </a>
          </span>
          <span className="vf-footer__legal-text">
            <a className="vf-footer__link" href="//www.ebi.ac.uk/about/contact">
              Full contact details
            </a>
          </span>
        </p>
        <p className="vf-footer__legal">
          <span className="vf-footer__legal-text">Copyright © EMBL 2024</span>
          <span className="vf-footer__legal-text">
            EMBL-EBI is part of the
            <a className="vf-footer__link" href="//www.embl.org">
              European Molecular Biology Laboratory
            </a>
          </span>
          <span className="vf-footer__legal-text">
            <a
              className="vf-footer__link"
              href="//www.ebi.ac.uk/about/terms-of-use"
            >
              Terms of use
            </a>
          </span>
        </p>
      </div>
    </footer>
  );
};

export default EBIFooter;
